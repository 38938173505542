h1 {
    margin-top: 0px;
}

p {
    margin-top: 0px;
}

.qr-image-wrapper section div {
    box-shadow: unset !important;
}

.text-link {
    color: inherit;
    text-decoration: inherit;
}

.root {
    height: 100%;
    background-color: #888;
}

@keyframes fadeIn {
    0% {
        opacity: '0%'
    }
    100% {
        opacity: '100%'
    }
}